<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="单元管理" width="1000" height="600" show-zoom @close="closeEvent">
    <template v-slot>
      <Unit :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import Unit from './Unit'
export default {
  components: {
    Unit
  },
  data() {
    return {
      selectRow: {},
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    let { items, data } = this.formOptions;
                    this.$Tools.enabledEvent(items, ["unitCount"], false)
                    data["parentId"] = this.currNode.id;
                    data["communityId"] = this.currNode.communityId;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "楼栋名称",
            // formatter: ({ cellValue, row, column }) => {
            //   return `${cellValue}号楼`
            // },
            minWidth: 120,
            editRender: {
              name: "$input",
              immediate: true,
              props: {
                maxlength: 24,
                placeholder: "请输入楼栋名称",
              },
            },
          },
          {
            field: "code",
            title: "楼栋编号",
            minWidth: 100,
            editRender: {
              name: "$input",
              immediate: true,
              props: {
                maxlength: 24,
                type: 'integer',
                placeholder: "请输入楼栋编号",
              },
            },
          },
          {
            field: "unitCount",
            title: "单元数",
            minWidth: 100,
            editRender: {
              name: "$VxeText",
              props: {
                type: 'text',
                status: "primary",
              },
              events: {
                click: (row) => {
                  this.selectRow = row;
                  this.$refs.xModal.open();
                }
              }
            },
          },
          {
            field: "layerCount",
            title: "总层数",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                type: 'integer',
                min: 1,
                max: 99,
                maxlength: 24,
                placeholder: "请输入总层数",
              },
            },
          },
          {
            field: "lift",
            title: "是否有电梯",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                placeholder: "请选择是否有电梯",
              },
            },
          },
          {
            field: "floorArea",
            title: "建筑面积",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入建筑面积",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row)
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "单元管理",
                  name: 'unitManage',
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  },
                },
                {
                  status: "primary",
                  label: "修改",
                  name: 'edit',
                  eventName: "queryEvent",
                  events: {
                    click: () => {
                      let { items } = this.formOptions;
                      this.$Tools.enabledEvent(items, ["unitCount"], true);
                    }
                  }
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 110,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["communityId"] = node.id;
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择小区')
                  }
                },
              }
            },
          },
          {
            field: "code",
            title: "楼栋编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: 'integer',
                min: 1,
                max: 99,
                maxlength: 24,
                placeholder: "请输入楼栋编号",
              },
              events: {
                change: ({ data, property }) => {
                  data["name"] = `${data[property]}号楼`
                }
              }
            },
          },
          {
            field: "name",
            title: "楼栋名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入楼栋名称",
              },
            },
          },
          {
            field: "layerCount",
            title: "总层数",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: 'integer',
                min: 1,
                max: 99,
                maxlength: 24,
                placeholder: "请输入总层数",
              },
            },
          },
          {
            field: "unitCount",
            title: "单元数",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "integer",
                min: 0,
                max: 99,
                maxlength: 2,
                placeholder: "请输入单元数",
              },
            },
          },
          {
            field: "lift",
            title: "是否有电梯",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                placeholder: "请选择是否有电梯",
              },
            },
          },
          {
            field: "floorArea",
            title: "建筑面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入建筑面积",
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 3,
                placeholder: "请输入排序号",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          code: [{ required: true, message: "请输入楼栋编号" }],
          name: [
            { required: true, message: "请输入楼名称" },
          ],
          lift: [{ required: true, message: "请选择是否有电梯" }],
          unitCount: [{ required: true, message: "请输入单元数" }],
          layerCount: [{ required: true, message: "请输入总层数" }],
          enabled: [{ required: true, message: "请选择状态" }],
          parentId: [{ required: true, message: "请选择所属小区" }],
          orderCode: [{ required: true, message: "请输入排序号" }],
        },
        // 新增功能字段初始化
        data: {
          name: "",
          enabled: "1",
          type: '1002',
          unitCount: "2",
          storeId: '100',
          status: '1',
          communityId: '',
          lift: '1',
          orderCode: "0"
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "楼栋名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入楼栋名称",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                maxlength: 24,
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          enabled: '',
        },
      },
    };
  },
  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },

    closeEvent() {
      let { xGrid } = this.$refs;
      xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
